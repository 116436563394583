import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import styled from 'styled-components';
import { CreateOrgPageGone } from 'createOrg/createOrgPageGone';
import {
  Redirect,
  validateUserSessionAndRedirectIfNeeded,
} from 'authentication';
import { AUTH_ERROR_PATH, AuthErrorPage } from 'authentication/AuthErrorPage';
import MissingOrgPage from 'missingOrg/missingOrgPage';
import IPAddressBlockedPage from 'ipAddressBlocked/ipAddressBlockedPage';
import { HandleChooseOrg } from './ChooseOrgPage';
import { Centered, CopyrightFooter } from '@ardoq/snowflakes';

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const AuthFrame = styled.div`
  max-width: 940px;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 940px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
`;

export const Login = () => {
  useEffect(() => {
    validateUserSessionAndRedirectIfNeeded(Redirect.APP);
  }, []);
  return null;
};

const Routes = () => {
  const currentPath = window.location.pathname;

  switch (currentPath) {
    case '/signup':
      return <CreateOrgPageGone />;
    case `/${AUTH_ERROR_PATH}`:
      return <AuthErrorPage />;
    case '/missing-org':
      return <MissingOrgPage />;
    case '/wrong-network':
      return <IPAddressBlockedPage />;
    case '/create-org':
      return <CreateOrgPageGone />;
    case '/choose-org':
      return <HandleChooseOrg />;
    default:
      return <Login />;
  }
};

const Container = () => {
  return (
    <>
      <AuthFrame>
        <Routes />
      </AuthFrame>
      <StyledFooter>
        <Centered>
          <CopyrightFooter />
        </Centered>
      </StyledFooter>
    </>
  );
};

export const initRouter = () => {
  createRoot(document.getElementById('root')!).render(<Container />);
};
