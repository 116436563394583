import { HTTPStatusCode } from '@ardoq/api-types';
import { logWarn } from '@ardoq/logging';
import ApiError from 'auth/ApiError';

export const AUTH_ERROR_PATH = 'auth-error';
const ACCESS_FORBIDDEN_PATH = 'unified-login/access-forbidden';
const AUTH_ERROR_MESSAGE_KEY = 'errorMessage';
const AUTH_ERROR_STATUS_KEY = 'status';

export const redirectToAccessForbiddenPage = () => {
  window.location.href = `/${ACCESS_FORBIDDEN_PATH}`;
};

export const redirectToAuthErrorPage = (errorMessage: string) => {
  const query = new URLSearchParams({
    [AUTH_ERROR_MESSAGE_KEY]: errorMessage,
  });
  window.location.assign(`/${AUTH_ERROR_PATH}?${query.toString()}`);
};

export const AuthErrorPage = () => {
  const query = new URLSearchParams(window.location.search);
  const isForbidden =
    query.get(AUTH_ERROR_STATUS_KEY) === HTTPStatusCode.FORBIDDEN.toString();

  if (isForbidden) {
    // Log warning to check if this is still in use
    logWarn(new Error('Forbidden'), 'Redirecting to access forbidden page.');
    redirectToAccessForbiddenPage();
    return null;
  }
  return (
    <ApiError errorMessage={query.get(AUTH_ERROR_MESSAGE_KEY) ?? undefined} />
  );
};
