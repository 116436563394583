import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const Wrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 20px;
`;

const IPAddressBlockedPage = () => (
  <Wrapper>
    <ResponsiveDoqWithSpeechBubble
      title="Invalid Network"
      message={
        <p>
          Based on the security settings in your org, it looks like you&apos;re
          accessing us from an invalid network. Please switch networks or, if
          you&apos;re unsure which network to join, please contact your
          administrator.
        </p>
      }
      doqType={DoqType.ALARM}
    />
  </Wrapper>
);

export default IPAddressBlockedPage;
