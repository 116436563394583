import { Link } from '@ardoq/typography';
import { Hero } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import styled from 'styled-components';

const ButtonLink = styled(Link)`
  display: flex;
  justify-content: center;
`;

const MissingOrgPage = () => (
  <Hero.Container>
    <Hero.Stack>
      <Hero.Header title="No organization found" />
      <Hero.PlainTextContainer>
        <Stack gap="medium">
          <div>Your account isn´t linked to any organization. </div>
          <div>
            Please contact{' '}
            <Link href="mailto:support@ardoq.com" hideIcon>
              support@ardoq.com
            </Link>{' '}
            for more information or sign in with a different account.
          </div>
        </Stack>
      </Hero.PlainTextContainer>
      <Hero.ActionsContainer>
        <ButtonLink type="primaryButton" href="/logout" hideIcon>
          Sign in with a different account
        </ButtonLink>
      </Hero.ActionsContainer>
    </Hero.Stack>
  </Hero.Container>
);

export default MissingOrgPage;
