import { logError, logWarn } from '@ardoq/logging';
import { api, userApi } from '@ardoq/api';
import {
  redirectToAccessForbiddenPage,
  redirectToAuthErrorPage,
} from './AuthErrorPage';
import { APICurrentUser } from '@ardoq/api-types';
import {
  isArdoqError,
  ArdoqError,
  getArdoqErrorMessage,
} from '@ardoq/common-helpers';

const handleError = (error: ArdoqError) => {
  if (api.isUnauthorized(error)) {
    redirectToLogin();
    return;
  }
  if (api.isForbidden(error)) {
    // Log warning to check if this is still in use
    logWarn(error, 'Redirecting to access forbidden page.');
    redirectToAccessForbiddenPage();
    return;
  }
  if (isArdoqError(error)) {
    logError(error);
    redirectToAuthErrorPage(getArdoqErrorMessage(error));
  }
};

const redirectToMainApp = () => {
  window.location.assign(
    new URLSearchParams(window.location.search).get('url') || `/app/home`
  );
};

export enum Redirect {
  LOGIN = 'login',
  APP = 'mainAPp',
}

/**
 * This function will Verify if a session is valid.
 * If not, it will redirect to login, "/choose-org" or "/missing-org", and return undefined.
 * If yes, redirect to main app if specified (no redirect otherwise) and return a valid user.
 */
export const validateUserSessionAndRedirectIfNeeded = async (
  redirect: Redirect
): Promise<APICurrentUser | undefined> => {
  const user = await userApi.fetchCurrentUser();
  if (isArdoqError(user)) {
    handleError(user);
    return;
  }
  // If user is not verified.
  if (!user.verified && redirect === Redirect.LOGIN) {
    redirectToLogin();
    return;
  }
  // If current user doesn't have a current Org
  if (!user.organization?.label) {
    // If current user has list of organizations
    // There should always be 2 or more organizations otherwise we would not be redirected here.
    if (user.organizations?.length >= 1) {
      window.location.assign('/choose-org');
    } else {
      // If current user has no or less than one list of organizations
      window.location.assign('/missing-org');
    }
    return;
  }

  if (redirect === Redirect.APP) {
    redirectToMainApp();
  }
  return user;
};

const createRedirectUri = () => {
  const queryParams = new URLSearchParams({
    ['redirect_uri']: window.location.pathname + window.location.search,
  });
  return `?${queryParams.toString()}`;
};

export const redirectToLogin = (persistCurrentUrl = true): void => {
  const redirectUri = persistCurrentUrl ? createRedirectUri() : '';

  window.location.assign(`/unified-login${redirectUri}`);
};

export const temporaryDisableAutoLogin = () => {
  document.cookie = 'autologin=no;path=/;SameSite=None;Secure';
};
