/* eslint-disable camelcase */
import Intercom from './intercom';
import { getAnalyticsKeys } from 'utils/status';
import { isAutomatedTestMode, isDevelopmentMode, isOnPremise } from 'appConfig';
import {
  getBrowserName,
  getBrowserPlatform,
  getBrowserVersionNumber,
} from '@ardoq/common-helpers';
import { generateCssSelectorPath } from 'utils/domUtils';
import { logError } from '@ardoq/logging';
import { getIn } from 'utils/collectionUtil';
import {
  APICurrentUser,
  APICurrentUserOrganizations,
  OrgAccessLevel,
  Organization,
} from '@ardoq/api-types';
import { Observable } from 'rxjs';
import { tap, pairwise, shareReplay, map } from 'rxjs/operators';
import * as sentry from '@ardoq/sentry';
import { trackApi } from '@ardoq/api';
import { Features, hasFeature } from '@ardoq/features';

const getHighestAccessLevelInAnyOrg = (
  organizations: APICurrentUserOrganizations[]
) => {
  const roles = organizations.map(({ role }) => role);
  if (roles.includes(OrgAccessLevel.ADMIN)) {
    return OrgAccessLevel.ADMIN;
  }
  if (roles.includes(OrgAccessLevel.WRITER)) {
    return OrgAccessLevel.WRITER;
  }
  if (roles.includes(OrgAccessLevel.READER)) {
    return OrgAccessLevel.READER;
  }
  if (roles.includes(OrgAccessLevel.CONTRIBUTOR)) {
    return OrgAccessLevel.CONTRIBUTOR;
  }
  return null;
};

const shouldTrackEvent = () => !isOnPremise() && !isAutomatedTestMode();

const CLIENT_ID = 'ardoq-front';

const createTrackingContext = () => ({
  userAgent: navigator.userAgent,
  page: {
    path: window.location.pathname,
    referrer: document.referrer,
  },
});

export const track = (name: string, metadata?: Record<string, unknown>) => {
  // eslint-disable-next-line no-console
  if (isDevelopmentMode()) console.info(`Tracking ${name}`, metadata);
  if (isOnPremise()) return;
  if (isAutomatedTestMode()) return;
  trackApi.trackEvent({
    name,
    metadata,
    context: createTrackingContext(),
    client: CLIENT_ID,
  });
};

type TrackClickParams = {
  clickId: string;
  namespace: string | null;
  cssSelector: string;
};
type TrackClick = (params: TrackClickParams) => Promise<void>;
const trackClick: TrackClick = async ({
  clickId,
  namespace = 'Unknown',
  cssSelector,
}) => {
  if (!shouldTrackEvent()) return;
  track('Clicked', { id: clickId, namespace, cssSelector });
};

const trackDeviceProperties = () =>
  trackEvent('Device properties', {
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    devicePixelRatio: window.devicePixelRatio,
    browserName: getBrowserName(),
    browserVersion: getBrowserVersionNumber(),
    browserPlatform: getBrowserPlatform(),
  });

const trackReferrer = () => {
  const params = new URLSearchParams(document.location.search);
  const ref = params.get('ref');

  if (ref) trackEvent('Accessed application from external link', { ref });
};

type DocumentOrEl = Document | HTMLElement;
export const registerTrackingClickListener = (
  container: DocumentOrEl = document
) => {
  const clickHandler: EventListenerOrEventListenerObject = event => {
    if (!(event.target instanceof Element)) {
      return;
    }

    const trackingClickTarget = event.target.closest('[data-click-id]');
    if (trackingClickTarget) {
      const clickId = trackingClickTarget.getAttribute('data-click-id')!;
      const namespaceNode = trackingClickTarget.closest(
        '[data-click-namespace]'
      );
      const namespace =
        namespaceNode && namespaceNode.getAttribute('data-click-namespace');

      trackClick({
        clickId,
        namespace,
        cssSelector: generateCssSelectorPath(
          event.target,
          trackingClickTarget as HTMLElement
        ),
      });
    }
  };

  const eventOptions: EventListenerOptions = { capture: true };
  container.addEventListener('click', clickHandler, eventOptions);
  return () => {
    container.removeEventListener('click', clickHandler, eventOptions);
  };
};

export const initializeTracking = () => {
  trackDeviceProperties();
  trackReferrer();
  registerTrackingClickListener();
};

export const initializeUserTracking = (
  currentUser: APICurrentUser,
  organization: Organization
) => {
  if (!shouldTrackEvent()) {
    return;
  }

  if (isDevelopmentMode()) {
    return;
  }

  if (currentUser.email !== 'public') {
    getAnalyticsKeys()
      .then(({ intercomKey, supportUrl }) => {
        Intercom.initialize(intercomKey, supportUrl);
      })
      .then(() => {
        Intercom.identify({
          user_id: currentUser._id,
          user_hash: currentUser['analytics-hash'],
          email: currentUser.email,
          name: currentUser.name,
          current_role: organization.role,
          highest_role: getHighestAccessLevelInAnyOrg(
            currentUser.organizations ?? []
          ),
          has_ardoq_studio_enabled: hasFeature(Features.NEW_CORE_JOURNEY),
          company: {
            id: organization._id,
            name: organization.name,
            label: organization.label,
            org_base_url: getIn(
              organization,
              ['custom-domain', 'base-url'],
              'https://app.ardoq.com'
            ),
          },
        });
      })
      .catch(error => logError(error));

    sentry.updateContext(currentUser._id, currentUser.email, organization._id);
  }
};

// Intercom has max 120 events, hence we whitelist the important ones
export const trackEvent = async (
  eventName: string,
  metadata: any = {},
  shouldSendToExternal = false
) => {
  if (!shouldTrackEvent()) {
    return;
  }

  if (shouldSendToExternal) {
    Intercom.track(eventName, metadata);
  }

  track(eventName, metadata);
};

/**
 * Side-affecting operator function that adds event tracking. Payload can be
 * used to generate the tracking attributes.
 *
 * NB! Do not include any personal information in tracking payloads.
 */
export const trackStreamEvent =
  <Payload>(
    getEventTrackAttrs: (payload: Payload) => {
      eventName: string;
      metadata?: Record<string, any>;
    }
  ) =>
  (obs$: Observable<Payload>) =>
    obs$.pipe(
      tap(payload => {
        const { eventName, metadata } = getEventTrackAttrs(payload);
        trackEvent(eventName, metadata);
      })
    );

type StateChange<T> = [oldValue: T, newValue: T];
type GetTrackStreamStateChangeEventArgs<T> = {
  predicate: (args: StateChange<T>) => boolean;
  eventName: string;
  metadata?: Record<string, any>;
  getMetaData?: (args: StateChange<T>) => Record<string, any>;
};

const getTrackStateChangeIfEligible =
  <T>({
    predicate,
    eventName,
    metadata = {},
    getMetaData,
  }: GetTrackStreamStateChangeEventArgs<T>) =>
  ([prev, next]: [T, T]) => {
    try {
      if (predicate([prev, next])) {
        trackEvent(eventName, {
          ...metadata,
          ...(getMetaData ? getMetaData([prev, next]) : {}),
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        logError(error);
      }
    }

    return next;
  };

/**
 * Creates an observable pipeline that tracks state changes based on the given arguments.
 * Be cautious with the `predicate` function: it should not mutate the `prev` or `next` state,
 * as this can cause unexpected side effects.
 * The `predicate` function should be a pure function that only evaluates the state and
 * returns a boolean value.
 */
export const trackStreamStateChange =
  <T>(args: GetTrackStreamStateChangeEventArgs<T>) =>
  (source: Observable<T>) =>
    source.pipe(
      pairwise(),
      map(getTrackStateChangeIfEligible(args)),
      shareReplay(1)
    );
