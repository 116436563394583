import styled from 'styled-components';
import { colors, radius } from '@ardoq/design-tokens';
interface ApiErrorProperties {
  errorMessage?: string;
  context?: string;
}

const AuthErrorContainer = styled.div`
  font-family: Helvetica, sans-serif;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: ${colors.surfaceDefault};
  border-radius: ${radius.r8};
  padding: 1rem;
  z-index: 1051;
  width: 900px;
  margin: auto;

  .authErrorText {
    padding: 5rem;

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    h1 {
      margin-bottom: 16px;
      font-weight: 600;
      letter-spacing: 2px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: ${colors.grey15};
    }
  }
`;

const DarkBackground = styled.div`
  height: 100%;
  display: flex;
  background-color: ${colors.surfaceBrand};
`;

const ApiError = ({ errorMessage, context }: ApiErrorProperties) => (
  <DarkBackground>
    <AuthErrorContainer id="authError">
      <div className="authErrorText">
        <h1 className="formTitle">Oh, snapper!</h1>
        <p>
          The application failed to load. Please check back in a few minutes, or{' '}
          <a href="https://ardoq.com/status/">have a look at the status page</a>{' '}
          if the error persists.
        </p>
        {context && <p>Context: {context}</p>}
        {errorMessage && <p>{errorMessage}</p>}
        <p>
          For urgent matters, please contact{' '}
          <a href="mailto:support@ardoq.com">support@ardoq.com</a>.
        </p>
      </div>
      <div>
        <img src="/img/vikingtransparent.png" alt="errorImage" />
      </div>
    </AuthErrorContainer>
  </DarkBackground>
);

export default ApiError;
