import { APICurrentUser, ChooseOrgOptions } from '@ardoq/api-types';
import { text1 } from '@ardoq/typography';
import { GhostButton } from '@ardoq/button';
import { Link } from '@ardoq/typography';
import styled from 'styled-components';
import { colors, s16 } from '@ardoq/design-tokens';
import { Hero, Stack } from '@ardoq/layout';
import { authApi } from '@ardoq/api';
import { useEffect, useState } from 'react';
import { redirectToAuthErrorPage } from 'authentication/AuthErrorPage';
import { isArdoqError } from '@ardoq/common-helpers';

const Text1 = styled.div`
  ${text1};
`;

const OrganizationList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${s16};

  button {
    border: 1px solid ${colors.grey80};
  }
`;

type ChooseOrgPageProps = Pick<APICurrentUser, 'organizations' | 'email'>;

const ChooseOrgPage = ({ organizations, email }: ChooseOrgPageProps) => (
  <Hero.Container>
    <Hero.Stack>
      <Hero.Header title="Choose organization" />
      <Hero.PlainTextContainer>
        <Text1>
          Your account {email} is currently linked to these organizations.
          Please select one to continue.
        </Text1>
      </Hero.PlainTextContainer>
      <Hero.ActionsContainer>
        <Stack gap="medium" align="center">
          <OrganizationList>
            {organizations.map(({ name, label }, index) => (
              <GhostButton
                key={index}
                isFlexible
                onClick={() => {
                  window.location.href = `/api/switchOrg?org=${label}`;
                }}
              >
                {name}
              </GhostButton>
            ))}
          </OrganizationList>
        </Stack>
      </Hero.ActionsContainer>
      <Hero.FooterContainer>
        <Stack gap="medium" align="center">
          <Link href="/logout" hideIcon type="secondary">
            Sign in with a different account
          </Link>
        </Stack>
      </Hero.FooterContainer>
    </Hero.Stack>
  </Hero.Container>
);

export const HandleChooseOrg = () => {
  const [chooseOrgOptions, setChooseOrgOptions] =
    useState<ChooseOrgOptions | null>(null);

  useEffect(() => {
    authApi
      .fetchChooseOrgOptions()
      .then(response => {
        if (isArdoqError(response)) {
          window.location.href = '/';
        } else if (response.organizations?.length === 0) {
          window.location.href = '/missing-org';
        } else {
          setChooseOrgOptions(response);
        }
      })
      .catch((error: Error) => {
        redirectToAuthErrorPage(
          error.message || 'Unhandled error when validating session.'
        );
      });
  }, []);

  if (chooseOrgOptions) {
    return (
      <ChooseOrgPage
        organizations={chooseOrgOptions.organizations}
        email={chooseOrgOptions.email}
      />
    );
  }
  return null;
};
