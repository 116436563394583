import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const Wrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 20px;
`;

export const CreateOrgPageGone = () => (
  <Wrapper>
    <ResponsiveDoqWithSpeechBubble
      title="New way to sign up"
      message={
        <p>
          To get started with the new, guided onboarding to Ardoq,{' '}
          <a href="mailto:support@ardoq.com">
            e-mail our friendly customer service team
          </a>
          , or schedule a demo{' '}
          <a href="https://content.ardoq.com/schedule-demo">here</a>.
        </p>
      }
      doqType={DoqType.PAGE_GONE}
    />
  </Wrapper>
);
